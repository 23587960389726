.leader-line {
  pointer-events: all !important;
}

html {
  position: unset !important;
  width: unset !important;
  --antd-wave-shadow-color: #757575 !important;
}

.ant-modal-wrap::-webkit-scrollbar,
html::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.ant-modal-wrap::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background: #eee;
  margin: 2px;
  border-radius: 4px;
}

.ant-modal-wrap::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 4px;
}

.ant-modal-wrap::-webkit-scrollbar-thumb:hover,
html::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

svg text {
  display: none;
}

.star {
  color: red !important;
}

.label {
  font-weight: bold !important;
}
